import * as actionTypes from "./actionTypes";
import {
  ACTIVATE_USERS_FAILED,
  ACTIVATE_USERS_START,
  ACTIVATE_USERS_SUCCESS,
  COMPANY_FETCH_FAILED,
  COMPANY_FETCH_START,
  COMPANY_FETCH_SUCCESS,
  COMPANY_SWITCH_FAILED,
  COMPANY_SWITCH_START,
  COMPANY_SWITCH_SUCCESS,
  CREATE_COMPANY_FAILED,
  CREATE_COMPANY_START,
  CREATE_COMPANY_SUCCESS,
  DEACTIVATE_USERS_FAILED,
  DEACTIVATE_USERS_START,
  DEACTIVATE_USERS_SUCCESS,
  DELETE_CONNECTED_ACCOUNT_FAILED,
  DELETE_CONNECTED_ACCOUNT_START,
  DELETE_CONNECTED_ACCOUNT_SUCCESS,
  DELETE_USERS_FAILED,
  DELETE_USERS_START,
  DELETE_USERS_SUCCESS,
  FETCH_ACTIVE_COMPANY_USERS_FAILED,
  FETCH_ACTIVE_COMPANY_USERS_START,
  FETCH_ACTIVE_COMPANY_USERS_SUCCESS,
  FETCH_COMPANY_USER_INFO_FAILED,
  FETCH_COMPANY_USER_INFO_START,
  FETCH_COMPANY_USER_INFO_SUCCESS,
  GET_CONNECTED_ACCOUNTS_FAILED,
  GET_CONNECTED_ACCOUNTS_START,
  GET_CONNECTED_ACCOUNTS_SUCCESS,
  GET_USER_LIST_FAILED,
  GET_USER_LIST_START,
  GET_USER_LIST_SUCCESS,
  REINVITE_USERS_FAILED,
  REINVITE_USERS_START,
  REINVITE_USERS_SUCCESS,
  FETCH_COMPANY_WISE_CONFIG_USER_START,
  FETCH_COMPANY_WISE_CONFIG_USER_SUCCESS,
  FETCH_COMPANY_WISE_CONFIG_USER_FAILED,
  UPDATE_COMPANY_WISE_CONFIG_USER_START,
  UPDATE_COMPANY_WISE_CONFIG_USER_SUCCESS,
  UPDATE_COMPANY_WISE_CONFIG_USER_FAILED,
  SEND_CHANGE_EMAIL_OTP_START,
  SEND_CHANGE_EMAIL_OTP_FAILED,
  SEND_CHANGE_EMAIL_OTP_SUCCESS,
  CHANGE_ACCOUNT_EMAIL_START,
  CHANGE_ACCOUNT_EMAIL_FAILED,
  CHANGE_ACCOUNT_EMAIL_SUCCESS,
  UPDATE_USER_TOUR_CONFIG_START,
  UPDATE_USER_TOUR_CONFIG_SUCCESS,
  UPDATE_USER_TOUR_CONFIG_FAILED,
  FETCH_TYPEFORM_FORMS_START,
  FETCH_TYPEFORM_FORMS_SUCCESS,
  FETCH_TYPEFORM_FORMS_FAILED,
  FETCH_JOTFORM_FORMS_START,
  FETCH_JOTFORM_FORMS_SUCCESS,
  FETCH_JOTFORM_FORMS_FAILED,
  CREATE_JOTFORM_FORMS_START,
  CREATE_JOTFORM_FORMS_SUCCESS,
  CREATE_JOTFORM_FORMS_FAILED,
  FETCH_BRAND_LIST_START,
  FETCH_BRAND_LIST_SUCCESS,
  FETCH_BRAND_LIST_FAILED,
  CREATE_BRAND_START,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAILED,
  FETCH_BRAND_DETAILS_START,
  FETCH_BRAND_DETAILS_SUCCESS,
  FETCH_BRAND_DETAILS_FAILED,
  DELETE_BRAND_START,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAILED,
  UPDATE_BRAND_START,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILED,
  DELETE_BRAND_ASSET_START,
  DELETE_BRAND_ASSET_SUCCESS,
  DELETE_BRAND_ASSET_FAILED,
  FETCH_FACEBOOK_ALBUMS_START,
  FETCH_FACEBOOK_ALBUMS_SUCCESS,
  FETCH_FACEBOOK_ALBUMS_FAILED,
  FETCH_FACEBOOK_ALBUM_IMAGES_START,
  FETCH_FACEBOOK_ALBUM_IMAGES_SUCCESS,
  FETCH_FACEBOOK_ALBUM_IMAGES_FAILED,
  UNPUBLISH_COMPANY_TEMPLATE_START,
  UNPUBLISH_COMPANY_TEMPLATE_SUCCESS,
  UNPUBLISH_COMPANY_TEMPLATE_FAILED,
  FETCH_COMPANY_TEMPLATES_START,
  FETCH_COMPANY_TEMPLATES_SUCCESS,
  FETCH_COMPANY_TEMPLATES_FAILED,
  FETCH_COMPANY_TEMPLATE_DETAILS_START,
  FETCH_COMPANY_TEMPLATE_DETAILS_SUCCESS,
  FETCH_COMPANY_TEMPLATE_DETAILS_FAILED,
  FETCH_COMPANY_TEMPLATE_BRANDS_START,
  FETCH_COMPANY_TEMPLATE_BRANDS_SUCCESS,
  FETCH_COMPANY_TEMPLATE_BRANDS_FAILED,
} from "./actionTypes";
import { userRequestManager as API, connectorRequestManager as connectorAPI } from "../../_helpers/requestManager";

const companyFetchStart = () => {
  return { type: COMPANY_FETCH_START };
};
const companyFetchSuccess = result => {
  return { type: COMPANY_FETCH_SUCCESS, result };
};
const companyFetchFailed = error => {
  return { type: COMPANY_FETCH_FAILED, error };
};

const companySwitchStart = () => {
  return { type: COMPANY_SWITCH_START };
};
const companySwitchSuccess = result => {
  return { type: COMPANY_SWITCH_SUCCESS, result };
};
const companySwitchFailed = error => {
  return { type: COMPANY_SWITCH_FAILED, error };
};

const getUserListStart = () => {
  return { type: GET_USER_LIST_START };
};

const getUserListFailed = error => {
  return { type: GET_USER_LIST_FAILED, error };
};

const getUserListSuccess = (userList, total, viewType, search_key) => {
  return { type: GET_USER_LIST_SUCCESS, userList, total, viewType, search_key };
};

const activateCompanyUsersStart = () => {
  return { type: ACTIVATE_USERS_START };
};

const activateCompanyUsersFailed = data => {
  return { type: ACTIVATE_USERS_FAILED, data };
};

const activateCompanyUsersSuccess = () => {
  return { type: ACTIVATE_USERS_SUCCESS };
};

const deactivateCompanyUsersStart = () => {
  return { type: DEACTIVATE_USERS_START };
};

const deactivateCompanyUsersFailed = () => {
  return { type: DEACTIVATE_USERS_FAILED };
};

const deactivateCompanyUsersSuccess = () => {
  return { type: DEACTIVATE_USERS_SUCCESS };
};

const reinviteCompanyUsersStart = () => {
  return { type: REINVITE_USERS_START };
};

const reinviteCompanyUsersFailed = data => {
  return { type: REINVITE_USERS_FAILED, data };
};

const reinviteCompanyUsersSuccess = () => {
  return { type: REINVITE_USERS_SUCCESS };
};

const deleteCompanyUsersStart = () => {
  return { type: DELETE_USERS_START };
};

const deleteCompanyUsersFailed = data => {
  return { type: DELETE_USERS_FAILED, data };
};

const deleteCompanyUsersSuccess = () => {
  return { type: DELETE_USERS_SUCCESS };
};

const getCompanyUserInfoStart = () => {
  return { type: FETCH_COMPANY_USER_INFO_START };
};

const getCompanyUserInfoSuccess = (data, userId) => {
  return { type: FETCH_COMPANY_USER_INFO_SUCCESS, data, userId };
};

const getCompanyUserInfoFailed = () => {
  return { type: FETCH_COMPANY_USER_INFO_FAILED };
};

const createCompanyStart = () => {
  return { type: CREATE_COMPANY_START };
};
const createCompanySuccess = result => {
  return { type: CREATE_COMPANY_SUCCESS, result };
};
const createCompanyFailed = error => {
  return { type: CREATE_COMPANY_FAILED, error };
};

const getActiveCompanyUsersStart = () => {
  return { type: FETCH_ACTIVE_COMPANY_USERS_START };
};

const getActiveCompanyUsersSuccess = activeUsers => {
  return { type: FETCH_ACTIVE_COMPANY_USERS_SUCCESS, activeUsers };
};

const getActiveCompanyUsersFailed = error => {
  return { type: FETCH_ACTIVE_COMPANY_USERS_FAILED, error };
};

const getConnectedAccountsStart = () => {
  return { type: GET_CONNECTED_ACCOUNTS_START };
};
const getConnectedAccountsSuccess = result => {
  return { type: GET_CONNECTED_ACCOUNTS_SUCCESS, result };
};
const getConnectedAccountsFailed = error => {
  return { type: GET_CONNECTED_ACCOUNTS_FAILED, error };
};

const deleteConnectedAccountStart = () => {
  return { type: DELETE_CONNECTED_ACCOUNT_START };
};
const deleteConnectedAccountSuccess = (result, connectedAccountId) => {
  return { type: DELETE_CONNECTED_ACCOUNT_SUCCESS, result, connectedAccountId };
};
const deleteConnectedAccountFailed = error => {
  return { type: DELETE_CONNECTED_ACCOUNT_FAILED, error };
};

const fetchCompanyUserConfigStart = () => {
  return { type: FETCH_COMPANY_WISE_CONFIG_USER_START };
};

const fetchCompanyUserConfigSuccess = data => {
  const { slug, success, ...respData } = data;
  return { type: FETCH_COMPANY_WISE_CONFIG_USER_SUCCESS, slug, success, respData };
};

const fetchCompanyUserConfigFailed = error => {
  return { type: FETCH_COMPANY_WISE_CONFIG_USER_FAILED, error };
};

const updateCompanyUserConfigStart = () => {
  return { type: UPDATE_COMPANY_WISE_CONFIG_USER_START };
};

const updateCompanyUserConfigSuccess = (data, slug) => {
  return { type: UPDATE_COMPANY_WISE_CONFIG_USER_SUCCESS, data, slug };
};

const updateCompanyUserConfigFailed = error => {
  return { type: UPDATE_COMPANY_WISE_CONFIG_USER_FAILED, error };
};

const getCompanyInfoStart = () => {
  return { type: actionTypes.GET_COMPANY_INFO_START };
};
const getCompanyInfoSuccess = companyInfo => {
  return { type: actionTypes.GET_COMPANY_INFO_SUCCESS, companyInfo };
};
const getCompanyInfoFailed = error => {
  return { type: actionTypes.GET_COMPANY_INFO_FAILED, error };
};

const sendChangeEmailStart = () => {
  return { type: SEND_CHANGE_EMAIL_OTP_START };
};

const sendChangeEmailFailed = error => {
  return { type: SEND_CHANGE_EMAIL_OTP_FAILED, error };
};

const sendChangeEmailSuccess = data => {
  return { type: SEND_CHANGE_EMAIL_OTP_SUCCESS, data };
};

const changeAccountEmailStart = () => {
  return { type: CHANGE_ACCOUNT_EMAIL_START };
};

const changeAccountEmailFailed = error => {
  return { type: CHANGE_ACCOUNT_EMAIL_FAILED, error };
};

const changeAccountEmailSuccess = data => {
  return { type: CHANGE_ACCOUNT_EMAIL_SUCCESS, data };
};

const updateTourConfigStart = () => {
  return { type: UPDATE_USER_TOUR_CONFIG_START };
};

const updateTourConfigFailed = error => {
  return { type: UPDATE_USER_TOUR_CONFIG_FAILED, error };
};

const updateTourConfigSuccess = data => {
  return { type: UPDATE_USER_TOUR_CONFIG_SUCCESS, data };
};

const fetchTypeformFormsStart = () => {
  return { type: FETCH_TYPEFORM_FORMS_START };
};

const fetchTypeformFormsFailed = error => {
  return { type: FETCH_TYPEFORM_FORMS_FAILED, error };
};

const fetchTypeformFormsSuccess = (data, assetName) => {
  let forms = data?.details;
  return { type: FETCH_TYPEFORM_FORMS_SUCCESS, forms, assetName };
};

const fetchJotformFormsStart = () => {
  return { type: FETCH_JOTFORM_FORMS_START };
};

const fetchJotformFormsSuccess = results => {
  return { type: FETCH_JOTFORM_FORMS_SUCCESS, results };
};

const fetchJotformFormsFailed = error => {
  return { type: FETCH_JOTFORM_FORMS_FAILED, error };
};

const connectJotformFormsStart = () => {
  return { type: CREATE_JOTFORM_FORMS_START };
};

const connectJotformFormsSuccess = results => {
  return { type: CREATE_JOTFORM_FORMS_SUCCESS, results };
};

const connectJotformFormsFailed = error => {
  return { type: CREATE_JOTFORM_FORMS_FAILED, error };
};

const fetchBrandListStart = () => {
  return { type: FETCH_BRAND_LIST_START };
};

const fetchBrandListSuccess = results => {
  return { type: FETCH_BRAND_LIST_SUCCESS, results };
};

const fetchBrandListFailed = error => {
  return { type: FETCH_BRAND_LIST_FAILED, error };
};

const createBrandStart = () => {
  return { type: CREATE_BRAND_START };
};

const createBrandSuccess = results => {
  return { type: CREATE_BRAND_SUCCESS, results };
};

const createBrandFailed = error => {
  return { type: CREATE_BRAND_FAILED, error };
};

const fetchBrandInfoStart = () => {
  return { type: FETCH_BRAND_DETAILS_START };
};

const fetchBrandInfoSuccess = brandInfo => {
  return { type: FETCH_BRAND_DETAILS_SUCCESS, brandInfo };
};

const fetchBrandInfoFailed = error => {
  return { type: FETCH_BRAND_DETAILS_FAILED, error };
};

const deleteBrandStart = () => {
  return { type: DELETE_BRAND_START };
};

const deleteBrandSuccess = data => {
  return { type: DELETE_BRAND_SUCCESS, data };
};

const deleteBrandFailed = error => {
  return { type: DELETE_BRAND_FAILED, error };
};

const updateBrandStart = () => {
  return { type: UPDATE_BRAND_START };
};

const updateBrandSuccess = (data, hashkey) => {
  return { type: UPDATE_BRAND_SUCCESS, data, hashkey };
};

const updateBrandFailed = error => {
  return { type: UPDATE_BRAND_FAILED, error };
};

const deleteBrandAssetStart = () => {
  return { type: DELETE_BRAND_ASSET_START };
};

const deleteBrandAssetSuccess = (data, assetId) => {
  return { type: DELETE_BRAND_ASSET_SUCCESS, data, assetId };
};

const deleteBrandAssetFailed = error => {
  return { type: DELETE_BRAND_ASSET_FAILED, error };
};

const fetchFacebookAlbumsStart = () => {
  return { type: FETCH_FACEBOOK_ALBUMS_START };
};

const fetchFacebookAlbumsSuccess = data => {
  return { type: FETCH_FACEBOOK_ALBUMS_SUCCESS, data };
};

const fetchFacebookAlbumsFailed = error => {
  return { type: FETCH_FACEBOOK_ALBUMS_FAILED, error };
};

const fetchFacebookAlbumImagesStart = () => {
  return { type: FETCH_FACEBOOK_ALBUM_IMAGES_START };
};

const fetchFacebookAlbumImagesSuccess = (data, albumId) => {
  return { type: FETCH_FACEBOOK_ALBUM_IMAGES_SUCCESS, data, albumId };
};

const fetchFacebookAlbumImagesFailed = error => {
  return { type: FETCH_FACEBOOK_ALBUM_IMAGES_FAILED, error };
};

const unpublishCompanyTemplateStart = () => {
  return { type: UNPUBLISH_COMPANY_TEMPLATE_START };
};

const unpublishCompanyTemplateSuccess = result => {
  return { type: UNPUBLISH_COMPANY_TEMPLATE_SUCCESS, result };
};

const unpublishCompanyTemplateFailed = error => {
  return { type: UNPUBLISH_COMPANY_TEMPLATE_FAILED, error };
};

const fetchCompanyTemplatesStart = () => {
  return { type: FETCH_COMPANY_TEMPLATES_START };
};

const fetchCompanyTemplatesSuccess = result => {
  return { type: FETCH_COMPANY_TEMPLATES_SUCCESS, result };
};

const fetchCompanyTemplatesFailed = error => {
  return { type: FETCH_COMPANY_TEMPLATES_FAILED, error };
};

const fetchCompanyTemplateDetailsStart = () => {
  return { type: FETCH_COMPANY_TEMPLATE_DETAILS_START };
};

const fetchCompanyTemplateDetailsSuccess = result => {
  return { type: FETCH_COMPANY_TEMPLATE_DETAILS_SUCCESS, result };
};

const fetchCompanyTemplateDetailsFailed = error => {
  return { type: FETCH_COMPANY_TEMPLATE_DETAILS_FAILED, error };
};

const fetchCompanyTemplateBrandsStart = () => {
  return { type: FETCH_COMPANY_TEMPLATE_BRANDS_START };
};

const fetchCompanyTemplateBrandsSuccess = result => {
  return { type: FETCH_COMPANY_TEMPLATE_BRANDS_SUCCESS, result };
};

const fetchCompanyTemplateBrandsFailed = error => {
  return { type: FETCH_COMPANY_TEMPLATE_BRANDS_FAILED, error };
};

export const fetchCompany = () => {
  return dispatch => {
    dispatch(companyFetchStart());

    return API.get("/companies")
      .then(r => dispatch(companyFetchSuccess(r.data)))
      .catch(e => dispatch(companyFetchFailed(e)));
  };
};

export const switchCompany = companyId => {
  let endPoint = `/companies/${companyId}/switch`;

  return dispatch => {
    dispatch(companySwitchStart());

    return API.post(endPoint)
      .then(r => dispatch(companySwitchSuccess(r.data)))
      .catch(e => dispatch(companySwitchFailed(e)));
  };
};

// Get company users
export const getUserList = payload => {
  let page = payload?.page ?? 1;
  let limit = payload?.limit ?? 50;
  let view = payload?.view ?? "ALL";
  let search_key = payload?.search_key ? true : false;

  let queryParams = `page=${page}&limit=${limit}&view=${view}`;

  if (payload?.search_key?.length > 0) {
    queryParams = `page=${page}&limit=${limit}&view=${view}&search_key=${payload?.search_key}`;
  }

  return dispatch => {
    dispatch(getUserListStart());
    return API.get(`/list?${queryParams}`)
      .then(r => dispatch(getUserListSuccess(r.data?.users, r.data?.total, view, search_key)))
      .catch(e => dispatch(getUserListFailed(e)));
  };
};

// Activate company users
export const activateCompanyUsers = payload => {
  return dispatch => {
    dispatch(activateCompanyUsersStart());
    return API.post(`/activate`, payload)
      .then(r => {
        if (r.data.success) dispatch(activateCompanyUsersSuccess(r.data));
        else dispatch(activateCompanyUsersFailed(r.data));
      })
      .catch(e => dispatch(activateCompanyUsersFailed(e.response.data)));
  };
};

// Deactivate company users
export const deactivateCompanyUsers = (payload, notify = false) => {
  return dispatch => {
    dispatch(deactivateCompanyUsersStart());
    return API.post(`/deactivate?notify=${notify}`, payload)
      .then(r => {
        if (r.data.success) dispatch(deactivateCompanyUsersSuccess(r.data));
        else dispatch(deactivateCompanyUsersFailed(r.data));
      })
      .catch(e => dispatch(deactivateCompanyUsersFailed(e)));
  };
};

// Reinvite company users
export const reinviteCompanyUsers = (payload, re_invite = false) => {
  return dispatch => {
    dispatch(reinviteCompanyUsersStart());
    return API.post(`/invite?re_invite=${re_invite}`, payload)
      .then(r => {
        if (r.data.success) dispatch(reinviteCompanyUsersSuccess(r.data));
        else dispatch(reinviteCompanyUsersFailed(r.data));
      })
      .catch(e => dispatch(reinviteCompanyUsersFailed(e.response.data)));
  };
};

// Delete company users
export const deleteCompanyUsers = (payload, change_ownership = false) => {
  const endpoint = change_ownership ? `/delete-user?change_ownership=${change_ownership}` : `/delete-user`;
  return dispatch => {
    dispatch(deleteCompanyUsersStart());
    return API.delete(endpoint, { data: payload })
      .then(r => {
        if (r.data.success) dispatch(deleteCompanyUsersSuccess(r.data));
        else dispatch(deleteCompanyUsersFailed(r.data));
      })
      .catch(e => dispatch(deleteCompanyUsersFailed(e)));
  };
};

// Get company user info
export const getCompanyUserInfo = userId => {
  return dispatch => {
    dispatch(getCompanyUserInfoStart());
    return API.get(`/${userId}`)
      .then(r => {
        r.data.name = [r.data?.firstname, r.data?.middlename, r.data?.lastname].join(" ").replace(/\s+/g, " ").trim();
        dispatch(getCompanyUserInfoSuccess(r.data, userId));
      })
      .catch(e => dispatch(getCompanyUserInfoFailed(e)));
  };
};

export const createCompany = payload => {
  return dispatch => {
    dispatch(createCompanyStart());

    return API.post("/user-company", payload)
      .then(r => dispatch(createCompanySuccess(r.data)))
      .catch(e => dispatch(createCompanyFailed(e)));
  };
};

// Get All actice company users
export const getActiveUserList = () => {
  let page = 1;
  let limit = 50;
  let view = "JOINED";
  let queryParams = `page=${page}&limit=${limit}&view=${view}`;
  return dispatch => {
    dispatch(getActiveCompanyUsersStart());
    return API.get(`/list?${queryParams}`)
      .then(r => dispatch(getActiveCompanyUsersSuccess(r.data?.users)))
      .catch(e => dispatch(getActiveCompanyUsersFailed(e)));
  };
};

export const getConnectedAccounts = () => {
  return dispatch => {
    dispatch(getConnectedAccountsStart());
    return connectorAPI
      .get("/")
      .then(r => dispatch(getConnectedAccountsSuccess(r.data)))
      .catch(e => dispatch(getConnectedAccountsFailed(e)));
  };
};

export const deleteConnectedAccount = connectedAccountId => {
  return dispatch => {
    dispatch(deleteConnectedAccountStart());
    return connectorAPI
      .delete(`/${connectedAccountId}`)
      .then(r => dispatch(deleteConnectedAccountSuccess(r.data, connectedAccountId)))
      .catch(e => dispatch(deleteConnectedAccountFailed(e)));
  };
};

export const getCompanyInfo = company_id => {
  return dispatch => {
    dispatch(getCompanyInfoStart());
    return API.get(`/companies/${company_id}`)
      .then(r => {
        dispatch(getCompanyInfoSuccess(r.data.data));
      })
      .catch(e => dispatch(getCompanyInfoFailed(e)));
  };
};

export const fetchCompanyUserConfig = slug => {
  return dispatch => {
    dispatch(fetchCompanyUserConfigStart());
    return API.get(`/${slug}/config`)
      .then(r => dispatch(fetchCompanyUserConfigSuccess(r.data)))
      .catch(e => dispatch(fetchCompanyUserConfigFailed(e)));
  };
};

export const updateCompanyUserConfig = (payload = {}) => {
  return dispatch => {
    dispatch(updateCompanyUserConfigStart());
    return API.put(`/config/update`, payload)
      .then(r => dispatch(updateCompanyUserConfigSuccess(r.data, payload.slug)))
      .catch(e => dispatch(updateCompanyUserConfigFailed(e)));
  };
};

export const sendChangeEmailOTP = (payload = {}) => {
  return dispatch => {
    dispatch(sendChangeEmailStart());
    return API.post("/change-email/send-otp", payload)
      .then(r => {
        if (r.data.success) {
          dispatch(sendChangeEmailSuccess(r.data));
        } else {
          dispatch(sendChangeEmailFailed(r.data));
        }
      })
      .catch(err => dispatch(sendChangeEmailFailed(err.response.data)));
  };
};

export const changeAccountEmail = (payload = {}) => {
  return dispatch => {
    dispatch(changeAccountEmailStart());
    return API.post("/change-email/validate-otp", payload)
      .then(r => {
        if (r.data.success) {
          dispatch(changeAccountEmailSuccess(r.data));
        } else {
          dispatch(changeAccountEmailFailed(r.data));
        }
      })
      .catch(err => dispatch(changeAccountEmailFailed(err.response.data)));
  };
};

// User Tour data update:
export const updateUserTourConfig = (userId, payload = {}) => {
  return dispatch => {
    dispatch(updateTourConfigStart());
    return API.put(`/${userId}/tour`, payload)
      .then(() => dispatch(updateTourConfigSuccess(payload)))
      .catch(e => dispatch(updateTourConfigFailed(e)));
  };
};

export const fetchTypeformForms = assetName => {
  return dispatch => {
    dispatch(fetchTypeformFormsStart());
    return connectorAPI
      .get(`/forms/typeform`)
      .then(r => dispatch(fetchTypeformFormsSuccess(r.data, assetName)))
      .catch(e => dispatch(fetchTypeformFormsFailed(e)));
  };
};

//jotform
export const fetchJotformForms = () => {
  return dispatch => {
    dispatch(fetchJotformFormsStart());
    return connectorAPI
      .get(`/forms/jotform`)
      .then(r => dispatch(fetchJotformFormsSuccess(r.data)))
      .catch(e => dispatch(fetchJotformFormsFailed(e)));
  };
};

export const connectJotformForms = apiKey => {
  const payload = {
    api_key: apiKey,
  };
  return dispatch => {
    dispatch(connectJotformFormsStart());
    return connectorAPI
      .post(`/forms/jotform`, payload)
      .then(r => dispatch(connectJotformFormsSuccess(r.data)))
      .catch(e => dispatch(connectJotformFormsFailed(e)));
  };
};

// Create Company Brand
export const createBrand = brandName => {
  return dispatch => {
    dispatch(createBrandStart());

    return API.post("/company/brands", brandName)
      .then(r => dispatch(createBrandSuccess(r.data)))
      .catch(e => dispatch(createBrandFailed(e)));
  };
};

// Fetch company brands
export const fetchBrands = payload => {
  let page = payload?.page ?? 1;
  let limit = payload?.limit ?? 50;
  const endPoint = `/company/brands?page=${page}&limit=${limit}`;

  return dispatch => {
    dispatch(fetchBrandListStart());

    return API.get(endPoint)
      .then(r => dispatch(fetchBrandListSuccess(r.data)))
      .catch(e => dispatch(fetchBrandListFailed(e)));
  };
};

// Fetch company brand details
export const fetchBrandDetils = brandId => {
  return dispatch => {
    dispatch(fetchBrandInfoStart());

    return API.get(`/company/brands/${brandId}`)
      .then(r => dispatch(fetchBrandInfoSuccess(r.data)))
      .catch(e => dispatch(fetchBrandInfoFailed(e)));
  };
};

// Create Company Brand
export const deleteBrand = (brandIds = []) => {
  return dispatch => {
    dispatch(deleteBrandStart());

    return API.post(`/company/brands/delete`, {
      deletedItemList: brandIds,
    })
      .then(r => dispatch(deleteBrandSuccess(r.data)))
      .catch(e => dispatch(deleteBrandFailed(e)));
  };
};

// Update Brand Info
export const updateBrand = (brandInfo, brandId, hashkey) => {
  return dispatch => {
    dispatch(updateBrandStart());

    return API.put(`/company/brands/${brandId}/update`, brandInfo)
      .then(r => dispatch(updateBrandSuccess(r.data, hashkey)))
      .catch(e => dispatch(updateBrandFailed(e)));
  };
};

// Delete Brand Asset
export const deleteBrandAsset = (brandId, assetType, assetId) => {
  return dispatch => {
    dispatch(deleteBrandAssetStart());

    return API.delete(`/company/brands/${brandId}/${assetType}/${assetId}`)
      .then(r => dispatch(deleteBrandAssetSuccess(r.data, assetId)))
      .catch(e => dispatch(deleteBrandAssetFailed(e)));
  };
};

// Fetch Facebook albums and images
export const fetchFacebookAlbums = () => {
  return dispatch => {
    dispatch(fetchFacebookAlbumsStart());
    return connectorAPI
      .get(`/facebook/albums`)
      .then(r => dispatch(fetchFacebookAlbumsSuccess(r.data)))
      .catch(e => dispatch(fetchFacebookAlbumsFailed(e)));
  };
};

export const fetchFacebookAlbumImages = payload => {
  let { albumId, page, limit } = payload;
  let url = `/facebook/album/images?album_id=${albumId}&limit=${limit}`;

  // Include page parameter in the URL if it's present in the payload
  if (page !== undefined && page !== null) {
    url += `&page=${page}`;
  }
  return dispatch => {
    dispatch(fetchFacebookAlbumImagesStart());
    return connectorAPI
      .get(url)
      .then(r => dispatch(fetchFacebookAlbumImagesSuccess(r.data, albumId)))
      .catch(e => dispatch(fetchFacebookAlbumImagesFailed(e)));
  };
};

// company templates
export const unpublishCompanyTemplate = templateId => {
  return dispatch => {
    dispatch(unpublishCompanyTemplateStart());

    return API.put(`/company/templates/${templateId}/unpublish`)
      .then(r => dispatch(unpublishCompanyTemplateSuccess(r.data)))
      .catch(e => dispatch(unpublishCompanyTemplateFailed(e)));
  };
};

export const fetchCompanyTemplates = ({ page = 1, limit = 25, brandId }) => {
  const brandQuery = brandId != "all" ? `&brand=${brandId}` : "";

  return dispatch => {
    dispatch(fetchCompanyTemplatesStart());

    return API.get(`/company/templates?page=${page}&limit=${limit}${brandQuery}`)
      .then(r => dispatch(fetchCompanyTemplatesSuccess(r.data)))
      .catch(e => dispatch(fetchCompanyTemplatesFailed(e)));
  };
};

export const fetchCompanyTemplateDetails = templateId => {
  return dispatch => {
    dispatch(fetchCompanyTemplateDetailsStart());

    return API.get(`/company/templates/${templateId}`)
      .then(r => dispatch(fetchCompanyTemplateDetailsSuccess(r.data)))
      .catch(e => dispatch(fetchCompanyTemplateDetailsFailed(e)));
  };
};

export const fetchCompanyTemplateBrands = () => {
  return dispatch => {
    dispatch(fetchCompanyTemplateBrandsStart());

    return API.get(`/company/templates/publish/brands`)
      .then(r => dispatch(fetchCompanyTemplateBrandsSuccess(r.data)))
      .catch(e => dispatch(fetchCompanyTemplateBrandsFailed(e)));
  };
};
