import { jwtDecode as jwt_decode } from "jwt-decode";
import * as actionTypes from "../actions/actionTypes";
import { fetchCookie, removeCookie } from "../../_helpers/utils";

// logout session if token expired
let decodedToken;
let accessToken;

if (localStorage.getItem("access_token")) accessToken = localStorage.getItem("access_token");
else {
  let cookieData = fetchCookie("_dh_signin_user");
  if (cookieData) {
    // This section is to set access token from cookies which created from federated login
    cookieData = cookieData.replace(/'/g, '"');
    cookieData = cookieData.replace(/\\054/g, ",");
    cookieData = cookieData.replace(/\\/g, "");
    try {
      let parsedData = JSON.parse(cookieData);
      if (parsedData?.access_token) {
        accessToken = parsedData?.access_token;
        localStorage.setItem("access_token", accessToken);
        removeCookie("_dh_signin_user");
      }
    } catch (error) {}
  }
}

if (accessToken) {
  decodedToken = jwt_decode(accessToken);
  let currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.clear();
  }
}

const initialState = {
  user: JSON.parse(localStorage.getItem("user")),
  loggedIn: !!accessToken,
};

if (decodedToken?.sub) initialState["uid"] = decodedToken?.sub;

export const authReducer = (originalState = initialState, action) => {
  let props = {};

  // clear flash msg details on state change
  if (originalState.flashMessage) props = { flashMessage: null };

  if (!["loading-bar/HIDE", "loading-bar/SHOW"].includes(action.type)) {
    props = { type: action.type, status: action?.error?.response?.status ?? null };
  }

  const state = {
    ...originalState,
    ...props,
  };

  switch (action.type) {
    case actionTypes.USER_LOGIN_START:
      return { ...state, loading: true, user: null };

    case actionTypes.USER_LOGIN_SUCCESS:
      if (action.loggedIn) {
        decodedToken = jwt_decode(action.user.access_token);
        localStorage.removeItem("user");
        localStorage.setItem("access_token", action.user.access_token);
      } else {
        localStorage.setItem("user", JSON.stringify(action.user));
      }
      return {
        ...state,
        user: action.loggedIn ? null : action.user,
        uid: decodedToken?.sub,
        loggedIn: action.loggedIn,
        loading: false,
      };

    case actionTypes.USER_LOGIN_FAILED:
      return { ...state, error: { message: action.error.message }, loading: false };

    case actionTypes.USER_LOGOUT_START:
      return { ...state, loading: true };

    case actionTypes.USER_LOGOUT_FAILED:
      return { ...state, loading: false, user: null };

    case actionTypes.USER_LOGOUT:
      localStorage.setItem("isLoggout", true);
      sessionStorage.removeItem("_dh_gmtvalue");
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      localStorage.removeItem("companies");
      localStorage.removeItem("template_groups");
      localStorage.removeItem("documents");
      localStorage.removeItem("selectedFilter");
      return { ...state, user: null, uid: null, loggedIn: false, error: false, loading: false };

    case actionTypes.USER_SIGNUP_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.user));
      return { ...state, user: action.user, loading: false };

    case actionTypes.USER_SIGNUP_FAILED:
      return { ...state, signup_error: { message: action.error.message }, loading: false };

    case actionTypes.USER_SIGNUP_START:
      return { ...state, verification_error: null, loggedIn: false, loading: true };

    case actionTypes.SIGNUP_VERIFICATION_START:
      return { ...state, loading: true };

    case actionTypes.SIGNUP_VERIFICATION_SUCCESS:
      return { ...state, info: action?.info, loading: false };

    case actionTypes.SIGNUP_VERIFICATION_FAILED:
      return {
        ...state,
        verification_error: { message: action.error?.response?.data?.message },
        loggedIn: false,
        loading: false,
      };

    case actionTypes.RESEND_VERIFICATION_START:
      return { ...state, resend_otp: "start" };

    case actionTypes.GET_USER_INFO_START:
      return { ...state, userSuccess: false, changeSignInInfo: null };

    case actionTypes.GET_USER_INFO_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.userInfo));
      return { ...state, user: action.userInfo, userUpdated: false, loading: false, userSuccess: true };

    case actionTypes.GET_USER_INFO_FAILED:
      return { ...state, userSuccess: false };

    case actionTypes.UPDATE_USER_COMPANY_INFO_START:
      return { ...state, loading: true };

    case actionTypes.UPDATE_COMPANY_INFO_START:
      return { ...state, loading: true };

    case actionTypes.CHANGE_PASSWORD_START:
      return { ...state, change_password_info: null, loading: true };

    case actionTypes.CHANGE_PASSWORD_FAILED:
      return { ...state, loading: false, change_password_info: action.data };

    case actionTypes.GET_COMPANY_INFO_SUCCESS:
      return { ...state, company: action.companyInfo, companyUpdated: false };

    case actionTypes.UPDATE_COMPANY_INFO_SUCCESS:
      localStorage.setItem(
        "user",
        JSON.stringify({ ...state.user, company: { ...state.user.company, name: action.companyName } })
      );
      return {
        ...state,
        companyUpdated: true,
        company: null,
        loading: false,
        user: { ...state.user, company: { ...state.user.company, name: action.companyName } },
      };

    case actionTypes.UPDATE_COMPANY_INFO_FAILED:
      return { ...state, loading: false };

    case actionTypes.TWO_STEP_OTP_SEND_START:
      return { ...state, two_step_info: null, loading: true };

    case actionTypes.TWO_STEP_OTP_SEND_SUCCESS:
      return { ...state, two_step_info: { resend_otp: action?.data?.success }, loading: false };

    case actionTypes.TWO_STEP_OTP_VALIDATION_START:
      return { ...state, two_step_info: null, loading: true };

    case actionTypes.TWO_STEP_OTP_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        two_step_info: { otp_validation: action?.validationInfo?.success, otp_token: action?.validationInfo?.token },
      };

    case actionTypes.ACTIVATE_TWO_STEP_START:
      return { ...state, two_step_info: null, loading: true };

    case actionTypes.ACTIVATE_TWO_STEP_SUCCESS:
      return { ...state, two_step_info: { activated: action?.activationInfo?.success }, loading: false };

    case actionTypes.ACTIVATE_TWO_STEP_FAILED:
      return { ...state, two_step_info: { activated: null }, loading: false };

    case actionTypes.DEACTIVATE_TWO_STEP_SUCCESS:
      localStorage.removeItem("user");
      return { ...state, two_step_info: { deactivated: action?.activationInfo?.success }, loading: false };

    case actionTypes.DEACTIVATE_TWO_STEP_START:
      return { ...state, loading: true };

    case actionTypes.DEACTIVATE_TWO_STEP_FAILED:
      return { ...state, loading: false };

    case actionTypes.GET_BACKUP_CODE_SUCCESS:
      return { ...state, two_step_info: { codes: action?.data } };

    case actionTypes.GENERATE_BACKUP_CODE_SUCCESS:
      return { ...state, two_step_info: { codes: action?.data } };

    case actionTypes.SEND_BACKUP_CODE_SUCCESS:
      return { ...state, two_step_info: { ...state.two_step_info, email_sent: action?.data?.success } };

    case actionTypes.DELETE_TWO_STEP_MODE_SUCCESS:
      return { ...state, two_step_info: { ...state.two_step_info, deleteMFA: action?.data?.success }, loading: false };

    case actionTypes.DELETE_TWO_STEP_MODE_START:
      return { ...state, loading: true };

    case actionTypes.DELETE_TWO_STEP_MODE_FAILED:
      return { ...state, loading: false };

    case actionTypes.FORGOT_PASSWORD_START:
      return { ...state, loading: true };

    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        email: action.email,
        forgot_password_status: action.status.success,
        error: null,
        loading: false,
      };

    case actionTypes.FORGOT_PASSWORD_FAILED:
      return { ...state, error: action.error, forgot_password_status: null, loading: false };

    case actionTypes.RESET_LINK_VERIFY_START:
      return { ...state, reset_password_success: false, loading: true };

    case actionTypes.RESET_LINK_VERIFY_SUCCESS:
      return {
        ...state,
        reset_link_success: action.status.success,
        reset_user_info: action.status,
        reset_password_success: false,
        loading: false,
      };

    case actionTypes.RESET_LINK_VERIFY_FAILED:
      return { ...state, error: action.error, loading: false };

    case actionTypes.RESET_PASSWORD_START:
      return { ...state, loading: true };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset_password_success: action.status.success,
        reset_password_info: action.status,
        reset_link_success: false,
        loading: false,
      };

    case actionTypes.RESET_PASSWORD_FAILED:
      return { ...state, error: action.error, loading: false };

    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return { ...state, change_password_info: action.data, loading: false };

    case actionTypes.UPDATE_USER_COMPANY_INFO_SUCCESS:
      return { ...state, success_msg: { company_update: true }, loading: false };

    case actionTypes.TWO_STEP_OTP_SEND_FAILED:
      return {
        ...state,
        two_step_info: { ...state.two_step_info, otp_sent_err_msg: action?.data?.message },
        loading: false,
      };

    case actionTypes.TWO_STEP_SIGNIN_AUTHENTICATION_START:
      return { ...state, loading: true, otp_error: null };

    case actionTypes.TWO_STEP_SIGNIN_AUTHENTICATION_FAILED:
      return { ...state, otp_error: action?.error?.message, loading: false };

    case actionTypes.UPDATE_USER_INFO_START:
      return { ...state, loading: true };

    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return { ...state, userUpdated: true, user: null };

    case actionTypes.UPDATE_USER_INFO_FAILED:
      return { ...state, loading: false };

    case actionTypes.ACCEPT_COMPANY_INVITATION_START:
      return { ...state, loading: true, invite: null };

    case actionTypes.ACCEPT_COMPANY_INVITATION_SUCCESS:
      localStorage.removeItem("user");
      let isLoggedin = action.data?.access_token || localStorage.getItem("access_token") ? true : false;
      if (action.data?.access_token) localStorage.setItem("access_token", action.data.access_token);
      return { ...state, loading: false, user: null, loggedIn: isLoggedin, invite: { message: action.data.message } };

    case actionTypes.ACCEPT_COMPANY_INVITATION_FAILED:
      return { ...state, loading: false, invite: { message: action.error.message } };

    case actionTypes.SEND_DELETE_EMAIL_START:
      return { ...state, loading: true };

    case actionTypes.SEND_DELETE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        flashMessage: { type: "success", message: "Confirmation Email Sent" },
      };

    case actionTypes.SEND_DELETE_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case actionTypes.DELETE_COMPANY_START:
      return { ...state, loading: true };

    case actionTypes.DELETE_COMPANY_SUCCESS:
      return { ...state, loading: false, company_delete_info: { ...action.data } };

    case actionTypes.DELETE_COMPANY_FAILED:
      return { ...state, loading: false, company_delete_info: { error: true } };

    case actionTypes.PROFILE_IMAGE_UPLOAD_START:
      return { ...state, loading: true };

    case actionTypes.PROFILE_IMAGE_UPLOAD_SUCCESS: {
      let updatedUser = { ...state.user, image: action.user.image };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return { ...state, user: updatedUser, loading: false };
    }

    case actionTypes.PROFILE_IMAGE_UPLOAD_FAILED:
      return { ...state, profile_image_upload_error: action.error, loading: false };

    case actionTypes.SIGNUP_LAST_STEP_START:
      return { ...state, loading: true, loggedIn: false };

    case actionTypes.SIGNUP_LAST_STEP_SUCCESS:
      localStorage.setItem("access_token", action?.data?.access_token);
      decodedToken = jwt_decode(action?.data?.access_token);
      return {
        ...state,
        access_token: action?.data?.access_token,
        loggedIn: true,
        loading: false,
        uid: decodedToken?.sub,
      };

    case actionTypes.SIGNUP_LAST_STEP_FAILED:
      return { ...state, signup_laststep_error: { message: action?.error?.message }, loading: false };

    case actionTypes.CHANGE_SIGNIN_METHOD_TO_EMAIL_START:
      return { ...state, loading: true };

    case actionTypes.CHANGE_SIGNIN_METHOD_TO_EMAIL_SUCCESS:
      return { ...state, loading: false, changeSignInInfo: action.data };

    case actionTypes.CHANGE_SIGNIN_METHOD_TO_EMAIL_FAILED:
      return { ...state, loading: false, changeSignInError: { message: action?.error?.message } };

    default:
      return state;
  }
};
