import qs from "qs";
import * as actionTypes from "./actionTypes";
import { authRequestManager as API, userRequestManager as userAPI } from "../../_helpers/requestManager";

const loginStart = () => {
  return { type: actionTypes.USER_LOGIN_START };
};
const loginFailed = error => {
  return { type: actionTypes.USER_LOGIN_FAILED, error };
};
const loginUser = user => {
  let loggedIn = true;
  if (!user?.access_token) loggedIn = false;
  return { type: actionTypes.USER_LOGIN_SUCCESS, user, loggedIn };
};

const logoutUserStart = () => {
  return { type: actionTypes.USER_LOGOUT_START };
};

const logoutUser = () => {
  return { type: actionTypes.USER_LOGOUT };
};

const logoutUserFailed = () => {
  return { type: actionTypes.USER_LOGOUT_FAILED };
};

const updateCompanyInfoStart = () => {
  return { type: actionTypes.UPDATE_COMPANY_INFO_START };
};

const updateCompanyInfoFailed = () => {
  return { type: actionTypes.UPDATE_COMPANY_INFO_FAILED };
};

const updateCompanyInfoSuccess = companyName => {
  return { type: actionTypes.UPDATE_COMPANY_INFO_SUCCESS, companyName };
};

const twoStepOTPSendStart = () => {
  return { type: actionTypes.TWO_STEP_OTP_SEND_START };
};

const twoStepOTPSent = data => {
  return { type: actionTypes.TWO_STEP_OTP_SEND_SUCCESS, data };
};

const twoStepOTPSendFailed = data => {
  return { type: actionTypes.TWO_STEP_OTP_SEND_FAILED, data };
};

const twoStepOTPValidationStart = () => {
  return { type: actionTypes.TWO_STEP_OTP_VALIDATION_START };
};

const twoStepOTPValidationSuccess = validationInfo => {
  return { type: actionTypes.TWO_STEP_OTP_VALIDATION_SUCCESS, validationInfo };
};

const twoStepOTPValidationFailed = () => {
  return { type: actionTypes.TWO_STEP_OTP_VALIDATION_FAILED };
};

const activate2StepStart = () => {
  return { type: actionTypes.ACTIVATE_TWO_STEP_START };
};

const activate2StepSuccess = activationInfo => {
  return { type: actionTypes.ACTIVATE_TWO_STEP_SUCCESS, activationInfo };
};

const activate2StepFailed = () => {
  return { type: actionTypes.ACTIVATE_TWO_STEP_FAILED };
};

const deactivate2StepStart = () => {
  return { type: actionTypes.DEACTIVATE_TWO_STEP_START };
};

const deactivate2StepSuccess = activationInfo => {
  return { type: actionTypes.DEACTIVATE_TWO_STEP_SUCCESS, activationInfo };
};

const deactivate2StepFailed = () => {
  return { type: actionTypes.DEACTIVATE_TWO_STEP_FAILED };
};

const forgotPasswordStart = () => {
  return { type: actionTypes.FORGOT_PASSWORD_START };
};

const forgotPasswordSuccess = status => {
  return { type: actionTypes.FORGOT_PASSWORD_SUCCESS, status };
};

const forgotPasswordFailed = error => {
  return { type: actionTypes.FORGOT_PASSWORD_FAILED, error };
};

const resetLinkVerifyStart = () => {
  return { type: actionTypes.RESET_LINK_VERIFY_START };
};
const resetLinkVerifySuccess = status => {
  return { type: actionTypes.RESET_LINK_VERIFY_SUCCESS, status };
};

const resetLinkVerifyFailed = () => {
  return { type: actionTypes.RESET_LINK_VERIFY_FAILED };
};

const resetPasswordStart = () => {
  return { type: actionTypes.RESET_PASSWORD_START };
};

const resetPasswordSuccess = status => {
  return { type: actionTypes.RESET_PASSWORD_SUCCESS, status };
};

const resetPasswordFailed = error => {
  return { type: actionTypes.RESET_PASSWORD_FAILED, error };
};
const changePasswordStart = () => {
  return { type: actionTypes.CHANGE_PASSWORD_START };
};

const changedPassword = data => {
  return { type: actionTypes.CHANGE_PASSWORD_SUCCESS, data };
};

const changePasswordFaild = data => {
  return { type: actionTypes.CHANGE_PASSWORD_FAILED, data };
};

const signupStart = () => {
  return { type: actionTypes.USER_SIGNUP_START };
};

const signupFailed = error => {
  return { type: actionTypes.USER_SIGNUP_FAILED, error };
};

const signupUser = user => {
  return { type: actionTypes.USER_SIGNUP_SUCCESS, user };
};

const signupVerificationStart = () => {
  return { type: actionTypes.SIGNUP_VERIFICATION_START };
};

const signupVerificationSuccess = info => {
  return { type: actionTypes.SIGNUP_VERIFICATION_SUCCESS, info };
};

const signupVerificationFailed = error => {
  return { type: actionTypes.SIGNUP_VERIFICATION_FAILED, error };
};

const resendVerificationStart = () => {
  return { type: actionTypes.RESEND_VERIFICATION_START };
};

const resendVerificationSuccess = () => {
  return { type: actionTypes.RESEND_VERIFICATION_SUCCESS };
};

const resendVerificationFailed = error => {
  return { type: actionTypes.RESEND_VERIFICATION_FAILED, error };
};

const getUserInfoStart = () => {
  return { type: actionTypes.GET_USER_INFO_START };
};

const getUserInfoSuccess = userInfo => {
  userInfo.name = `${userInfo.firstname} ${userInfo.lastname}`;
  // Chekcing middle name
  if (userInfo?.middlename) userInfo.name = `${userInfo.firstname} ${userInfo.middlename} ${userInfo.lastname}`;
  return { type: actionTypes.GET_USER_INFO_SUCCESS, userInfo };
};

const getUserInfoFailed = error => {
  return { type: actionTypes.GET_USER_INFO_FAILED, error };
};

const getBackupCodeStart = () => {
  return { type: actionTypes.GET_BACKUP_CODE_START };
};

const getBackupCodeSuccess = data => {
  return { type: actionTypes.GET_BACKUP_CODE_SUCCESS, data };
};

const getBackupCodeFailed = error => {
  return { type: actionTypes.GET_BACKUP_CODE_FAILED, error };
};

const generateBackupCodeStart = () => {
  return { type: actionTypes.GENERATE_BACKUP_CODE_START };
};

const generateBackupCodeSuccess = data => {
  return { type: actionTypes.GENERATE_BACKUP_CODE_SUCCESS, data };
};

const generateBackupCodeFailed = error => {
  return { type: actionTypes.GENERATE_BACKUP_CODE_FAILED, error };
};

const sendBackupCodeStart = () => {
  return { type: actionTypes.SEND_BACKUP_CODE_START };
};

const sendBackupCodeSuccess = data => {
  return { type: actionTypes.SEND_BACKUP_CODE_SUCCESS, data };
};

const sendBackupCodeFailed = error => {
  return { type: actionTypes.SEND_BACKUP_CODE_FAILED, error };
};

const delete2StepModeStart = () => {
  return { type: actionTypes.DELETE_TWO_STEP_MODE_START };
};

const delete2StepModeSuccess = data => {
  return { type: actionTypes.DELETE_TWO_STEP_MODE_SUCCESS, data };
};

const delete2StepModeFailed = error => {
  return { type: actionTypes.DELETE_TWO_STEP_MODE_FAILED, error };
};

const updateUserInfoStart = () => {
  return { type: actionTypes.UPDATE_USER_INFO_START };
};

const updateUserInfoFailed = () => {
  return { type: actionTypes.UPDATE_USER_INFO_FAILED };
};

const updateUserInfoSuccess = () => {
  return { type: actionTypes.UPDATE_USER_INFO_SUCCESS };
};

const firstCompanyUpdateStart = () => {
  return { type: actionTypes.UPDATE_USER_COMPANY_INFO_START };
};

const firstCompanyUpdateSuccess = userInfo => {
  return { type: actionTypes.UPDATE_USER_COMPANY_INFO_SUCCESS, userInfo };
};

const firstCompanyUpdateFailed = error => {
  return { type: actionTypes.UPDATE_USER_COMPANY_INFO_FAILED, error };
};

const acceptUserInvitationStart = () => {
  return { type: actionTypes.ACCEPT_COMPANY_INVITATION_START };
};

const acceptUserInvitationSuccess = data => {
  return { type: actionTypes.ACCEPT_COMPANY_INVITATION_SUCCESS, data };
};

const acceptUserInvitationFailed = error => {
  return { type: actionTypes.ACCEPT_COMPANY_INVITATION_FAILED, error };
};

const twoStepAuthenticationStart = () => {
  return { type: actionTypes.TWO_STEP_SIGNIN_AUTHENTICATION_START };
};

const twoStepAuthenticationFailed = error => {
  return { type: actionTypes.TWO_STEP_SIGNIN_AUTHENTICATION_FAILED, error };
};

const send2StepSigninOTPStart = () => {
  return { type: actionTypes.SEND_2_STEP_SIGNIN_OTP_START };
};

const send2StepSigninOTPSuccess = () => {
  return { type: actionTypes.SEND_2_STEP_SIGNIN_OTP_SUCCESS };
};

const send2StepSigninOTPFailed = () => {
  return { type: actionTypes.SEND_2_STEP_SIGNIN_OTP_FAILED };
};

const sendCompanyDeleteEmailStart = () => {
  return { type: actionTypes.SEND_DELETE_EMAIL_START };
};

const sendCompanyDeleteEmailSuccess = () => {
  return { type: actionTypes.SEND_DELETE_EMAIL_SUCCESS };
};

const sendCompanyDeleteEmailFailed = () => {
  return { type: actionTypes.SEND_DELETE_EMAIL_FAILED };
};

const deleteCompanyStart = () => {
  return { type: actionTypes.DELETE_COMPANY_START };
};

const deleteCompanySuccess = data => {
  return { type: actionTypes.DELETE_COMPANY_SUCCESS, data };
};

const deleteCompanyFailed = data => {
  return { type: actionTypes.DELETE_COMPANY_FAILED, data };
};

const profileImageUploadStart = () => {
  return { type: actionTypes.PROFILE_IMAGE_UPLOAD_START };
};
const profileImageUploadFailed = error => {
  return { type: actionTypes.PROFILE_IMAGE_UPLOAD_FAILED, error };
};
const profileImageUploadSuccess = user => {
  return { type: actionTypes.PROFILE_IMAGE_UPLOAD_SUCCESS, user };
};

const signupLastStepStart = () => {
  return { type: actionTypes.SIGNUP_LAST_STEP_START };
};
const signupLastStepFailed = error => {
  return { type: actionTypes.SIGNUP_LAST_STEP_FAILED, error };
};
const signupLastStep = data => {
  return { type: actionTypes.SIGNUP_LAST_STEP_SUCCESS, data };
};

const ChangeSigninMethodToEmailStart = () => {
  return { type: actionTypes.CHANGE_SIGNIN_METHOD_TO_EMAIL_START };
};
const ChangeSigninMethodToEmailFailed = error => {
  return { type: actionTypes.CHANGE_SIGNIN_METHOD_TO_EMAIL_FAILED, error };
};
const ChangeSigninMethodToEmailSuccess = data => {
  return { type: actionTypes.CHANGE_SIGNIN_METHOD_TO_EMAIL_SUCCESS, data };
};

export const login = ({ email, password, remember_me }) => {
  return dispatch => {
    dispatch(loginStart());

    return API.post(
      `/login?remember_me=${remember_me}`,
      qs.stringify({
        username: email,
        password: password,
      }),
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
    )
      .then(r => {
        if (r.status !== 200) dispatch(loginFailed(r.data));
        return r;
      })
      .then(r => {
        if (r.data.otp_verified === false) r.data["signup_email"] = email; // set signup email if not activated user try to login
        dispatch(loginUser(r.data));
      })
      .catch(e => {
        dispatch(loginFailed(e.response.data));
      });
  };
};

export const logout = () => {
  return dispatch => {
    dispatch(logoutUserStart());
    return API.post(`/logout`, null, {
      headers: {
        "X-Authtoken": localStorage.getItem("access_token"),
      },
    })
      .then(() => dispatch(logoutUser()))
      .catch(() => dispatch(logoutUserFailed()));
  };
};

export const updateCompanyInfo = (company_id, payload) => {
  return dispatch => {
    dispatch(updateCompanyInfoStart());
    return userAPI
      .put(`/companies/${company_id}`, payload)
      .then(() => {
        dispatch(updateCompanyInfoSuccess(payload.name));
      })
      .catch(e => dispatch(updateCompanyInfoFailed(e)));
  };
};
export const forgotPassword = email => {
  return dispatch => {
    dispatch(forgotPasswordStart());
    return API.post(`/forgot-password`, { email: email })
      .then(r => {
        dispatch(forgotPasswordSuccess(r.data));
      })
      .catch(e => {
        dispatch(forgotPasswordFailed(e.response.data));
      });
  };
};

export const resetLinkVerify = token => {
  return dispatch => {
    dispatch(resetLinkVerifyStart());
    return API.get(`/reset-password/${token}/verify`)
      .then(r => dispatch(resetLinkVerifySuccess(r.data)))
      .catch(e => dispatch(resetLinkVerifyFailed(e)));
  };
};

export const resetPassword = (token, payload) => {
  return dispatch => {
    dispatch(resetPasswordStart());
    return userAPI
      .put(`/reset-password/${token}`, payload, {
        headers: {
          "X-Auth": "NoAuth",
        },
      })
      .then(r => {
        dispatch(resetPasswordSuccess(r.data));
      })
      .catch(e => {
        dispatch(resetPasswordFailed(e));
      });
  };
};

export const getUserInfo = () => {
  return dispatch => {
    dispatch(getUserInfoStart());
    return API.get(`/me`)
      .then(r => dispatch(getUserInfoSuccess(r.data)))
      .catch(e => dispatch(getUserInfoFailed(e)));
  };
};

export const updateUserInfo = payload => {
  return dispatch => {
    dispatch(updateUserInfoStart());
    return userAPI
      .put(`/me`, payload)
      .then(r => dispatch(updateUserInfoSuccess(r.data)))
      .catch(e => dispatch(updateUserInfoFailed(e)));
  };
};

export const sendTwoStepOTP = payload => {
  return dispatch => {
    dispatch(twoStepOTPSendStart());
    return userAPI
      .post("/two-factor-authentication/send-otp", payload)
      .then(r => {
        if (r.data.success) dispatch(twoStepOTPSent(r.data));
        else dispatch(twoStepOTPSendFailed(r.data));
      })
      .catch(err => dispatch(twoStepOTPSendFailed(err.response.data)));
  };
};

export const validateTwoStepOTP = payload => {
  return dispatch => {
    dispatch(twoStepOTPValidationStart());
    return userAPI
      .post("/two-factor-authentication/validate-otp", payload)
      .then(resp => dispatch(twoStepOTPValidationSuccess(resp.data)))
      .catch(err => dispatch(twoStepOTPValidationFailed(err)));
  };
};

export const activate2Step = payload => {
  return dispatch => {
    dispatch(activate2StepStart());
    return userAPI
      .post("/two-factor-authentication/activate", payload)
      .then(r => {
        if (r.data.success) dispatch(activate2StepSuccess(r.data));
        else dispatch(activate2StepFailed(r.data));
      })
      .catch(err => dispatch(activate2StepFailed(err)));
  };
};

export const deactivate2Step = () => {
  return dispatch => {
    dispatch(deactivate2StepStart());
    return userAPI
      .post("/two-factor-authentication/deactivate")
      .then(resp => dispatch(deactivate2StepSuccess(resp.data)))
      .catch(err => dispatch(deactivate2StepFailed(err)));
  };
};

export const getBackupCode = () => {
  return dispatch => {
    dispatch(getBackupCodeStart());
    return userAPI
      .get("/two-factor-authentication/backup-codes")
      .then(resp => dispatch(getBackupCodeSuccess(resp.data)))
      .catch(err => dispatch(getBackupCodeFailed(err)));
  };
};

export const generateBackupCode = () => {
  return dispatch => {
    dispatch(generateBackupCodeStart());
    return userAPI
      .post("/two-factor-authentication/regenerate-backup-codes")
      .then(resp => {
        if (resp.data.success) dispatch(generateBackupCodeSuccess(resp.data));
        else dispatch(generateBackupCodeFailed(resp.data));
      })
      .catch(err => dispatch(generateBackupCodeFailed(err)));
  };
};

export const sendBackupCode = () => {
  return dispatch => {
    dispatch(sendBackupCodeStart());
    return userAPI
      .post("/two-factor-authentication/send-backup-codes")
      .then(resp => dispatch(sendBackupCodeSuccess(resp.data)))
      .catch(err => dispatch(sendBackupCodeFailed(err)));
  };
};

export const delete2stepMode = payload => {
  return dispatch => {
    dispatch(delete2StepModeStart());
    return userAPI
      .put("/two-factor-authentication/delete", null, {
        params: {
          type: payload.type,
          order: payload.order,
        },
      })
      .then(resp => dispatch(delete2StepModeSuccess(resp.data)))
      .catch(err => dispatch(delete2StepModeFailed(err)));
  };
};

export const changePassword = payload => {
  return dispatch => {
    dispatch(changePasswordStart());
    return userAPI
      .put("/change-password", payload)
      .then(r => {
        dispatch(changedPassword(r.data));
      })
      .catch(e => {
        dispatch(changePasswordFaild(e.response.data));
      });
  };
};

export const register = payload => {
  return dispatch => {
    dispatch(signupStart());
    return API.post("/signup", payload)
      .then(r => {
        let userData = r.data;
        userData["signup_email"] = payload.email;
        dispatch(signupUser(userData));
      })
      .catch(e => {
        dispatch(signupFailed(e.response.data));
      });
  };
};

export const signupVerification = payload => {
  return dispatch => {
    dispatch(signupVerificationStart());
    return API.post("/signup-verification", payload)
      .then(r => {
        if (r.status !== 200) dispatch(signupVerificationFailed(r.data));
        return r;
      })
      .then(r => {
        if (r.status == 200) dispatch(signupVerificationSuccess(r.data));
      })
      .then(r => {
        dispatch(loginUser(r.data));
      })
      .catch(e => {
        dispatch(signupVerificationFailed(e));
      });
  };
};

export const resendVerificationCode = user_id => {
  return dispatch => {
    dispatch(resendVerificationStart());
    return userAPI
      .post(
        `/${user_id}/resend-activation`,
        {},
        {
          headers: {
            "X-Auth": "NoAuth",
          },
        }
      )
      .then(() => dispatch(resendVerificationSuccess()))
      .catch(e => dispatch(resendVerificationFailed(e)));
  };
};

export const firstCompanyUpdate = (companyId, payload) => {
  return dispatch => {
    dispatch(firstCompanyUpdateStart());
    return userAPI
      .put(`/companies/${companyId}?first_update=true`, payload)
      .then(r => {
        if (r.data.success) dispatch(firstCompanyUpdateSuccess(r.data));
        else dispatch(firstCompanyUpdateFailed(r.data));
      })
      .catch(e => dispatch(firstCompanyUpdateFailed(e)));
  };
};

// For join invited user
export const acceptUserInvitation = (companyId, invitation_token, payload = {}) => {
  return dispatch => {
    dispatch(acceptUserInvitationStart());
    return userAPI
      .post(`/${companyId}/${invitation_token}/join`, payload, {
        headers: {
          "X-Auth": "NoAuth",
        },
      })
      .then(r => dispatch(acceptUserInvitationSuccess(r.data)))
      .catch(e => dispatch(acceptUserInvitationFailed(e.response.data)));
  };
};

// For 2 step otp verification
export const twoStepVerification = (payload, primary = true, backup_code = false) => {
  return dispatch => {
    dispatch(twoStepAuthenticationStart());
    return API.post(`/verify-otp/${payload?.otp_token}?primary=${primary}&backup_code=${backup_code}`, {
      otp: payload?.otp,
    })
      .then(r => {
        if (r?.data?.success) dispatch(loginUser(r.data));
        else dispatch(twoStepAuthenticationFailed({ message: r.data.message }));
      })
      .catch(e => dispatch(twoStepAuthenticationFailed(e.response.data)));
  };
};

// For 2 step otp verification
export const send2StepSigninOTP = (otp_token, primary = true) => {
  return dispatch => {
    dispatch(send2StepSigninOTPStart());
    return API.post(`/send-otp/${otp_token}?primary=${primary}`)
      .then(r => {
        if (r?.data?.success) dispatch(send2StepSigninOTPSuccess());
      })
      .catch(() => dispatch(send2StepSigninOTPFailed()));
  };
};

// For send company delete email
export const sendCompanyDeleteEmail = company_id => {
  return dispatch => {
    dispatch(sendCompanyDeleteEmailStart());
    return userAPI
      .post(`/companies/${company_id}/delete`)
      .then(r => dispatch(sendCompanyDeleteEmailSuccess(r?.data)))
      .catch(() => dispatch(sendCompanyDeleteEmailFailed()));
  };
};

// For delete company
export const deleteCompany = (companyId, token, deleted = false) => {
  return dispatch => {
    dispatch(deleteCompanyStart());
    return userAPI
      .delete(`/companies/${companyId}/delete_token/${token}?deleted=${deleted}`, {
        headers: {
          "X-Auth": "NoAuth",
        },
      })
      .then(r => dispatch(deleteCompanySuccess(r?.data)))
      .catch(e => dispatch(deleteCompanyFailed(e.response.data)));
  };
};

// for profile photo upload
export const profileImageUpload = file => {
  return dispatch => {
    dispatch(profileImageUploadStart());
    return userAPI
      .post(`/profile-image`, file)
      .then(r => dispatch(profileImageUploadSuccess(r.data?.user)))
      .catch(e => dispatch(profileImageUploadFailed(e)));
  };
};

export const clearCurrentUserSession = () => {
  return dispatch => {
    dispatch(logoutUser());
  };
};

//For Sign Up Last Step
export const signupFinalStep = payload => {
  return dispatch => {
    dispatch(signupLastStepStart());
    return API.post("/create-company", payload)
      .then(r => {
        dispatch(signupLastStep(r?.data));
      })
      .catch(e => {
        dispatch(signupLastStepFailed(e.response?.data));
      });
  };
};

//For Change Sign In Method To Email
export const ChangeSignInMethodToEmail = payload => {
  return dispatch => {
    dispatch(ChangeSigninMethodToEmailStart());
    return userAPI
      .put(`/create-password`, payload)
      .then(r => dispatch(ChangeSigninMethodToEmailSuccess(r.data)))
      .catch(e => dispatch(ChangeSigninMethodToEmailFailed(e.response.data)));
  };
};
